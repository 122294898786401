export class Client {
  constructor(apiUrl) {
    this.url = apiUrl
  }

  async get(datasetKey, selector) {
    const url = new URL(this.url + '/e2t/v4/' + datasetKey + '/select')
    url.search = new URLSearchParams(selector)
    const response = await window.fetch(url, { credentials: 'same-origin' })
    return await response.json()
  }
}
