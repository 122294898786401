import * as Vue from '@moreonion/petite-vue'
import { mergeConfig } from '../component-utils.js'

import { Component } from './Component.js'
import { Input } from './Input.js'

class CurrencyFormatter {
  constructor (currency, options = {}) {
    this.currency = currency
    this.formatter = new Intl.NumberFormat(options.locale || document.querySelector('html').lang, {
      style: 'currency',
      currency,
      ...options
    })
  }

  /**
   * Get the currency symbol.
   *
   * @returns The currency symbol
   */
  getSymbol () {
    return this.formatter.formatToParts(0).find(p => p.type === 'currency').value
  }

  format (number) {
    return this.formatter.format(number)
  }
}

export class DonationAmount extends Component {
  constructor(config) {
    config = mergeConfig(config, {
      preset: {
        type: 'radios',
        params: {
          enabled: true,
          amounts: [],
          presentation: 'buttons',
        }
      },
      custom: {
        type: 'number',
        params: {
          enabled: true,
        }
      },
      params: {
        currency: {
          source: 'value',
          value: 'EUR',
          component: null,
        },
        numberFormat: {
          maximumFractionDigits: 0,
        },
        showFractionDigits: false,
      }
    })
    if (config.params.showFractionDigits) {
      // If it is unset the number of fraction digits adjusts to the currency.
      delete config.params.numberFormat.maximumFractionDigits
    }
    super(config)
    this.propagateValues = false

    if (config.params.currency.source !== 'value') {
      console.warn('currency.source.component: not yet implemented, using value instead.')
    }
    this.currency = new CurrencyFormatter(config.params.currency.value, config.params.numberFormat)
    this.presetComponent = null
    this.customComponent = null

    const preset = config.preset
    if (preset.params.enabled && preset.params.amounts.length > 0) {
      preset.params.name = config.params.name + '.preset'
      preset.params.required = config.params.required && config.custom == null
      preset.params.options = preset.params.amounts.map((amount) => {
        return { value: amount, label: this.currency.format(amount) }
      })
      this.presetComponent = new Component(preset)
      this.addChild(this.presetComponent)
    }
    else if (!config.custom) {
      // If no radios are displayed we must display the number field.
      config.custom = { type: 'number', params: { enabled: true } }
    }

    if (!this.presetComponent || config.custom?.params.enabled) {
      const custom = config.custom
      custom.params.currencySymbol = this.currency.getSymbol()
      custom.params.name = config.params.name + '.custom'
      custom.params.required = config.params.required
      this.customComponent = new Input(custom)
      this.addChild(this.customComponent)
    }
  }

  vueScope() {
    const scope = super.vueScope()
    scope.presetComponent = this.presetComponent
    scope.customComponent = this.customComponent
    return scope
  }

  bindValue() {
    super.bindValue()
    Vue.effect(() => {
      this.reactive.value = this.presetComponent?.reactive.value || this.customComponent?.reactive.value
    })
  }
}
