import * as Vue from '@moreonion/petite-vue'
import { formPrefill } from '@moreonion/formprefill/src/main.js'

import * as e2t from './e2t.js'
import * as geoip from './geoip.js'
import * as utils from './component-utils.js'
import * as session from './session.js'

import { ComponentTree, TemplateCollection } from './form-display'

/**
 * Main class for interacting with a form with all its configs and templates.
 */
export class VueApp {
  /**
   * Create a new form.
   *
   * @param {object} config - The form configuration
   * @param {TemplateCollection} templates - Object representing all the component templates.
   */
  constructor(formTree, templates) {
    this.formTree = formTree
    this.templates = templates
  }

  /**
   * Generate the vue-scope for a component.
   *
   * @param {Component} component - The component’s config object.
   * @returns {object} Variables that are made available to the petite-vue template.
   */
  createVueScope(component) {
    const template = this.templates.getElement(component.templateName())
    return {
      $template: '#' + template.id,
      ...component.vueScope(),
    }
  }

  /**
   * Render a form into a container element.
   *
   * @param {HTMLElement} container - The container element to render the form in.
   * @param {object} additionalScope - Additional objects made available to the vue app scope.
   * @return {Promise<Vue>} The vue app object.
   */
  async mount(container, additionalScope = {}) {
    // Get the container element to create the component for the config's root node.
    // Each component is responsible for rendering its child components with the
    // appropriate v-scope attribute. Usually: v-scope="createVueScope(childComponent)".
    if (!container.hasAttribute('v-scope')) {
      container.setAttribute('v-scope', 'createVueScope(rootComponent)')
    }

    // Generate the vue app’s root scope and mount it.
    const rootComponent = this.formTree.root
    const scope = {
      rootComponent,
      createVueScope: (component) => this.createVueScope(component),
      mounted: () => {
        // Trigger an event after the form is mounted.
        container.dispatchEvent(new Event('dom-inserted', { bubbles: true }))
        formPrefill(container, rootComponent.config.formPrefill || {})
      },
      ...utils, // inject functions from componentUtils.js into the app scope
      ...additionalScope, // inject anything contained within this argument into the app scope
    }
    const app = Vue.createApp(scope)
    container.setAttribute('v-on:vue:mounted', 'mounted()')
    app.mount(container)
    return app
  }
}

/**
 * Render a form by loading component templates into the DOM,
 * then using them to create HTML within a container element.
 *
 * @param {HTMLElement} container - The container element to render the form in.
 * @param {object} options - URLs for form configuration and template folder, e.g.:
 *   {
 *     configUrl: "http://example.com/form-config.json",
 *     templatesUrl: "http://example.com/templates/",
 *     apiUrl: "http://example.com/api",
 *     successCallback: function (formData, responseData) {
 *       window.location.assign("https://example.com/thank-you")
 *     },
 *   }
 * @return {ComponentTree} The form tree.
 */
export async function renderForm(container, options = {}) {
  const config = options.config || await fetch(options.configUrl).then(response => response.json())
  if (!config) {
    console.error('No form configuration given')
    return {}
  }
  const geoipConfig = (config.params || {}).geoip
  if (geoipConfig && geoipConfig.apiKey) {
    // Start with the formPrefill default of using sessionStorage if nothing else was configured.
    config.formPrefill = {
      ...{ stores: ['sessionStorage'] }, ...config.formPrefill
    }
    config.formPrefill.stores.push(
      new geoip.PrefillStore(geoipConfig.apiKey, geoipConfig.keys || ['s:country'])
    )
  }

  // Pass the succesCallback through to the form component.
  if (options.successCallback) {
    config.meta = config.meta || {}
    config.meta.successCallback = options.successCallback
  }

  const formTree = new ComponentTree(config)
  const templates = new TemplateCollection()
  if (options.templatesUrl) {
    await templates.loadMissingTemplates(formTree.getComponentTypesInUse(), options.templatesUrl)
  }

  const form = new VueApp(formTree, templates)
  const apiUrl = options.apiUrl ?? '/api'
  form.mount(container)
  form.formTree.root.e2tClient = new e2t.Client(apiUrl)
  form.formTree.root.sessionFetcher = new session.Fetcher(apiUrl + '/auth/v1/anonymous-session')
  form.formTree.root.requestSession()
  return formTree
}
