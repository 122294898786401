import { evaluateEnabledFlag } from '../component-utils.js'

import { Component } from './Component.js'

export class OptIn extends Component {
  constructor(config) {
    super(config)
    evaluateEnabledFlag(this.params, 'intro')
    this.propagateValues = false
  }

  bindValue() {
    super.bindValue()
    this.reactive.value = this.getValue()
  }

  getValue() {
    switch (this.params.display) {
      case 'radios':
        return this.radiosValue()
      case 'checkbox':
        return this.checkboxValue()
      default:
        return null
    }
  }

  radiosValue() {
    const selectedInput = this.$el.querySelector('input:checked')
    const value = selectedInput ? selectedInput.value : null
    const valueMap = {
      null: 'not-selected',
      1: 'opt-in',
      0: this.params.no_is_optout ? 'opt-out' : 'no-change',
    }
    return 'radios:' + valueMap[value]
  }

  checkboxValue() {
    let valueMap = {
      true: 'opt-in',
      false: 'no-change',
    }
    const value = this.$el.querySelector('input:checked') !== null
    let type = 'checkbox'
    if (this.params.no_is_optout) {
      valueMap.false = 'opt-out'
      if (this.params.disable_opt_in) {
        valueMap.true = 'no-change'
      }
    }
    if (this.params.checkbox_inverted) {
      type = 'checkbox-inverted'
      valueMap = {
        true: valueMap.false,
        false: valueMap.true,
      }
    }
    return type + ':' + valueMap[value]
  }
}
