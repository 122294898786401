import * as Vue from '@moreonion/petite-vue'

export class Component {
  constructor(config) {
    this.config = config
    this.children = []
    this.type = config.type
    this.params = config.params || {}
    this.meta = config.meta || {}
    /**
     * Component wrapped in a reactivity proxy.
     *
     * All manipulations of the component that should affect templates must go through the proxy.
     */
    this.reactive = Vue.reactive(this)
    // This will be replaced with a reference to the form submission data.
    this.data = {}
    // … unless the parent component has this flag set to false.
    this.propagateValues = true

    this.reactive.value = config.params?.value ?? null
  }

  addChild(component) {
    this.children.push(component)
    component.form = this.form
    if (this.propagateValues) {
      component.data = this.data
    }
  }

  mounted($el) {
    this.$el = $el
    this.bindValue()
  }

  bindValue() {
    if (this.params.name) {
      Vue.effect(() => {
        this.data[this.params.name] = this.reactive.value
      })
    }
  }

  /**
   * Get the template file name.
   *
   * @returns The file name of the template to render this component.
   */
  templateName() {
    return this.type
  }

  /**
   * Get the templates needed by this component and its child-components.
   *
   * @returns An set containing all the required template names.
   */
  neededTemplates() {
    const templates = new Set()
    templates.add(this.templateName())
    for (const child of this.children) {
      child.neededTemplates().forEach((template) => templates.add(template))
    }
    return templates
  }

  /**
   * Generate the v-scope object to pass to the template.
   *
   * @returns The v-scope available to the template.
   */
  vueScope() {
    return {
      component: this.reactive,
      type: this.type,
      params: this.params,
      meta: this.meta,
      children: this.children,
    }
  }

  /**
   * Set an element’s attributes based on the component‘s parameters.
   *
   * Usually this is invoked as v-effect in a vue template.
   *
   * @param {HTMLElement} $el The element which’s attributes should be set.
   * @param {String[]} exclude Attribute names that should be excluded.
   */
  attributesFromParams($el, exclude = []) {
    const excludeSet = new Set(exclude)
    excludeSet.add('dataset')
    Object.keys(this.params).forEach(attribute => {
      if (attribute in $el && !excludeSet.has(attribute)) {
        $el[attribute] = this.params[attribute]
      }
    })
    // Copy dataset to set data-attributes as well.
    Object.keys(this.params.dataset || {}).forEach(key => {
      $el.dataset[key] = this.params.dataset[key]
    })
  }
}
