import * as altcha from 'altcha-lib'
import * as authHeader from '@mitmaro/http-authorization-header'
/**
 * Extract a challenge from a Response object.
 *
 * @param {Response} response
 * @returns {object}|null
 */
export function extractChallenge(response) {
  let header
  if (response.status === 401 && (header = response.headers.get('WWW-Authenticate'))) {
    const parsed = authHeader.parse(header)
    if (parsed.scheme === 'Altcha') {
      return Object.fromEntries(parsed.values)
    }
  }
}

/**
 * Solve an ALTCHA challenge.
 *
 * @param {object} params
 * @returns {Promise<object>}
 */
export async function solveChallenge(params) {
  const solver = altcha.solveChallenge(params.challenge, params.salt, params.algorithm, params.maxnumber)
  const solution = await solver.promise
  const ret = {
    ...params,
    number: solution.number.toString(),
  }
  delete ret.maxnumber
  return ret
}

/**
 * Solve a ALTCHA challenge and add the solution to the request headers.
 *
 * @param {object} params
 * @param {Request} request
 */
export async function solveForRequest(params, request) {
  const solution = await solveChallenge(params)
  request.headers.set('Authorization', authHeader.create('Altcha', Object.entries(solution)))
}
