import { Component } from './Component.js'
import { DonationAmount } from './DonationAmount.js'
import { Form } from './Form.js'
import { Input } from './Input.js'
import { NoValue } from './NoValue.js'
import { OptIn } from './OptIn.js'
import { Postcode } from './Postcode.js'
import { Recaptcha } from './Recaptcha.js'

export const byType = {
  checkbox: Input,
  checkboxes: Component,
  'donation-amount': DonationAmount,
  email: Input,
  fieldset: Component,
  form: Form,
  hidden: Component,
  html: NoValue,
  input: Input,
  number: Input,
  'opt-in': OptIn,
  password: Input,
  postcode: Postcode,
  radio: Input,
  radios: Component,
  recaptcha: Recaptcha,
  select: Component,
  submit: NoValue,
  text: Input,
  textarea: Component,
}
